import { Button, Drawer } from 'antd'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const CookieAccepts = () => {
  const { t } = useTranslation()
  const [cookies, setCookie] = useCookies(['acceptCookies'])

  const handleAccept = () => {
    setCookie('acceptCookies', 'true', { maxAge: 365 * 24 * 60 * 60 })
    mixpanel.track('New User')
    localStorage.setItem('acceptCookies', 'true')
  }

  return (
    <Drawer mask={false} contentWrapperStyle={{height: 'auto'}} placement="bottom" open={cookies.acceptCookies !== 'true'} closable={false}>
      <P>
        {t('cookiesMessage')}{' '}
        <a href="https://www.termsfeed.com/live/b42b2cf2-4847-4b76-8130-f24edb695618" rel="noreferrer" target="_blank">
          {t('cookiePolicy')}
        </a>
      </P>
      <Button type="primary" size="large" onClick={handleAccept}>
        {t('accept')}
      </Button>
    </Drawer>
  )
}

const P = styled.p`
  font-size: 1.2em;
`

export default CookieAccepts
