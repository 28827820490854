import { Button, Checkbox, Divider, Modal, Typography } from 'antd'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const EPNDisclosure = () => {
  const { t, i18n } = useTranslation()
  const [cookies, setCookie] = useCookies(['epnDisclosure'])
  const [open, setOpen] = useState(cookies.epnDisclosure !== 'true')
  const [hide, setHide] = useState(false)

  const handleOk = () => {
    setOpen(false)
    if (hide) {
      setCookie("epnDisclosure", "true", { maxAge: 365 * 24 * 60 * 60 })
    }
  }

  return (
    <Modal style={{ direction: i18n.language === 'he' ? 'rtl' : 'ltr'}} title="Compopo EPN disclosure" open={open} closable={false} footer={[
      <Checkbox key="hide" checked={hide} onChange={(e) => setHide(e.target.checked)}>{t("EPNDisclosure.hideNextTime")}</Checkbox>,
      <Divider key="divider" type="vertical" />,
      <Button key="ok" type="primary" onClick={handleOk}>OK</Button>
    ]}>
      <ol>
        <li>{t("EPNDisclosure.p1")}</li>
        <li>{t("EPNDisclosure.p2")}</li>
        <li>{t("EPNDisclosure.p3")}</li>
        <li>{t("EPNDisclosure.p4")}</li>
      </ol>
      <Typography.Paragraph>
        {t("EPNDisclosure.acknowledge")}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t("EPNDisclosure.questions")}
      </Typography.Paragraph>
    </Modal>
  )
}

export default EPNDisclosure