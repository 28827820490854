import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Title } = Typography

const TermsOfUse = () => {
  return (
    <Root>
      <Title level={2}>Terms Of Use</Title>
      <p>
        1. Compopo (hereinafter referred to as the "Site") is arepresentative website ("Worldwide shopping sites comparing") for "Compopo" and you are invited to take part in it subject to your consent to the Terms of
        Use set forth below.
      </p>
      <p>
        2. In addition, the use of this site for all its content and services offered therein, downloads of files, media such as images and videos and the various content offered to the visitors may change from time to
        time or depending on the type of content.
      </p>
      <p>3. The site's management reserves the right to update the terms of use presented below from time to time and without special notice or mention on the various website channels.</p>
      <Title level={4}>Intelectual Property</Title>
      <p>
        4. The Site as well as all its information including the design of the Site, the site code, media files including graphics, videos, photos, texts, files offered for download and any other material displayed on
        the Site belongs in full to the aforementioned site and constitute exclusive intellectual property of the "Compopo" site and should not be used without prior written permission from the "Site" Compopo.
      </p>
      <p>In addition, you may not distribute, copy, reproduce, publish, imitate or process pieces of code, graphics, videos, trademarks or any other media and content without your prior writtenpermission.</p>
      <p>
        5. The content of the site we strive to provide you with the information presented on the Site without interruption but may be due to technical considerations, third party or other malfunctions, disturbances in
        the availability of the site.
      </p>
      <p>
        6. Links to an external website do not constitute a guarantee that these are safe, high-quality or reliable sites and visiting them is done on your own personal opinion only and are within the sole responsibility
        of the user of the site.
      </p>
      <p>
        7. The smart comparison engine we created is in a constant process of improving its accuracy, and therefore, the site does not take responsibility for any malfunction in any order\shipping problem or ordering a
        product the customer did not intend to purchase, all purchases of the customer will be on third parties stores and without any liability on the part of the "Compopo" site, and to have no financial or other
        compensation as a result.
      </p>
      <p>
        8. The content offered on the Site is solely owned by "Compopo" and should not be used which is intended as stated in these regulations (see Section 3 (except in cases where it is otherwise stated or in cases
        where it is stated that the copyright belongs to an external entity.
      </p>
      <p>
        9. Managing users and visitors to the site The site management reserves the right to block any user or by blocking the IP address of his computer, the MCID address of his computer or even in accordance with the
        country of origin without the need to provide an excuse which is acceptable to the user.
      </p>
      <p>
        10.The site team / site management will do everything in its power to protect the details of users registered on the site/ subscribers registered on the site. In cases where a third party is able to gain access
        to agreed information hereby, users and members of the site to which he has wondered any claim, claim or demand against the site's staff “Compopo".
      </p>
      <p>
        11. Full disclosure, this site may use cookies, especially for registered and subscription users, and intrastat interfaces to maintain anonymous statistical documentation of surfers and user traffic analysis,
        browsing habits and click and time-of-stay. At all times and alone than users connected to the saved information site is completely anonymous and does not have the user's name or any other identifying
        information.
      </p>
      <p>
        12. Jurisdiction when you use the Site and in the event that each division has emerged, you agree below that the foregoing is under the exclusive jurisdiction of Israeli law, using only the Israeli court system
        in the Tel Aviv District.
      </p>
      <Title level={5}>By using "Compopo” website, I agree to everything written in this document.</Title>
    </Root>
  )
}

const Root = styled.div`
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
`

export default TermsOfUse
