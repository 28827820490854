import { useEffect } from "react"

export default function useLoadMore(hasNextPage: boolean, isFetchingNextPage: boolean, fetchNextPage: () => void) {
  useEffect(() => {
    if (!hasNextPage) {
      return
    }

    const loadMore = () => {
      if (isFetchingNextPage) {
        return
      }
      if (document.scrollingElement!.scrollTop + document.body.offsetHeight > document.scrollingElement!.scrollHeight - 1000) {
        fetchNextPage()
      }
    }

    window.addEventListener('scroll', loadMore)

    return () => {
      window.removeEventListener('scroll', loadMore)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isFetchingNextPage])
}