import React from 'react'
import { Dropdown, Menu } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import ReactCountryFlag from 'react-country-flag'
import { COUNTRIES } from '../../common/Constants'
import { MenuButton } from '../MenuButton/MenuButton'

interface Props {
  value: string
  onChange: (value: string) => void
}

const CountriesMenu = ({ value, onChange }: Props) => {
  return (
    <Dropdown trigger={['click']} overlay={renderMenu(value, ({ key }) => onChange(key))}>
      <MenuButton>
        <ReactCountryFlag style={{ fontSize: '2em' }} svg countryCode={value} />
      </MenuButton>
    </Dropdown>
  )
}

const renderMenu = (key: string, onClick: MenuClickEventHandler) => {
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[key]}
      items={[
        { label: 'Ship to', type: 'group' },
        ...COUNTRIES.map(({ value, label }) => ({
          key: value,
          label: (
            <div>
              <ReactCountryFlag style={{ marginRight: '10px' }} svg countryCode={value} />
              <span>{label}</span>
            </div>
          ),
        })),
      ]}
    />
  )
}

export default CountriesMenu
