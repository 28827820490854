import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import ContactUs from './pages/ContactUs';
import Home from './pages/Home'
import QnA from './pages/QnA';
import TermsOfUse from './pages/TermsOfUse';


function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-W6MK22C'})
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/qna" element={<QnA />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
