import React from 'react'
import styled from 'styled-components'
import { isMobile } from '../../common/utils'
import Carousel from '../Carousel'

import { useTranslation } from 'react-i18next';

const EXPLANATIONS = [
  { src: '/explanations/truck.png', alt: 'Truck', info: 'explanationCarousel.truck'},
  { src: '/explanations/airplane.png', alt: 'Airplane', info: 'explanationCarousel.airplane'},
  { src: '/explanations/dollar.png', alt: 'Dollar', info: 'explanationCarousel.coin' },
  { src: '/explanations/payment.png', alt: 'Payment', info: 'explanationCarousel.creditCard' },
  { src: '/explanations/sorting.png', alt: 'Sorting', info: 'explanationCarousel.sorting' },
  { src: '/explanations/worldwide.png', alt: 'Worldwide', info: 'explanationCarousel.globe'},
  { src: '/explanations/marketplaces.png', alt: 'Marketplaces', info: 'explanationCarousel.table'}
]
const Explanations = () => {
  const { t } = useTranslation();
  return (
    <StyledCarousel autoScroll={isMobile()}>
      {EXPLANATIONS.map(({ src, alt, info }) => (
        <Box key={alt}>
          <img src={src} alt={alt} />
          <div dangerouslySetInnerHTML={{__html: t(info)}} />
        </Box>
      ))}
    </StyledCarousel>
  )
}

const Box = styled.div`
  text-align: center;
  padding: 0 15px;
`

const StyledCarousel = styled(Carousel)`
  margin: 15px 0;
`

export default Explanations
