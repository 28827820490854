import React from 'react'
import styled from 'styled-components'
import { IComparedItem } from '../../models/ComparedItem'
import ProductCard from '../ProductCard'
import { SkeletonCard } from '../ProductCard'
import ProductCardWithReview from '../ProductCard/ProductCardWithReview'
import { IReview } from '../../models/Review'

interface Props {
  items: IComparedItem[]
  isLoading?: boolean
  currency?: string
}

const skeletonItems = Array.from(Array(10).keys())

const ProductsGrid = ({ items, isLoading, currency }: Props) => {
  const skeletonTitle = 'Compopo'
  return (
    <Root>
      {isLoading && skeletonItems.map((item) => <SkeletonCard key={`mock_${item}`} title={skeletonTitle} />)}
      {!isLoading && items.map((item, index) => !!item.reviewId ? <ProductCardWithReview item={item as any as IReview} key={index} currency={currency} /> : <ProductCard item={item} key={index} currency={currency} />)}
    </Root>
  )
}

export default ProductsGrid

const Root = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
