import React from 'react'
import styled from 'styled-components'
import { isMobile } from '../../common/utils'
import { IComparedItem } from '../../models/ComparedItem'
import Carousel from '../Carousel'
import ProductCard, { SkeletonCard } from '../ProductCard'
import ProductCardWithReview from '../ProductCard/ProductCardWithReview'
import { IReview } from '../../models/Review'

interface Props {
  items: IComparedItem[]
  isLoading: boolean
  currency?: string
}

const skeletonItems = Array.from(Array(10).keys())

const ProductsList = ({ items, currency, isLoading }: Props) => {
  return (
    <StyledCarousel autoScroll={isMobile()}>
      {isLoading && skeletonItems.map((item) => <SkeletonCard short key={`mock_${item}`} />)}
      {!isLoading && items.map((item, index) => (!!item.reviewId ? <ProductCardWithReview item={item as any as IReview} key={index} currency={currency} /> : <ProductCard item={item} key={index} currency={currency} short />))}
    </StyledCarousel>
  )
}

const StyledCarousel = styled(Carousel)`
  gap: 20px;
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`

export default ProductsList
