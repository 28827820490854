import { Button } from 'antd'
import styled from 'styled-components'

export const MenuButton = styled(Button).attrs({type: 'text'})`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #ffffff;
    background-color: rgba(256, 256, 256, 0.2);
  }
  &:focus {
    color: #ffffff;
  }
`