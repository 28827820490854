import React, { useRef, useState, useEffect, MouseEventHandler, WheelEventHandler, useMemo, ReactNode, CSSProperties } from 'react'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'
import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { isMobile } from '../../common/utils'

interface Props {
  children: ReactNode[]
  vertical?: boolean
  size?: 'default' | 'small'
  style?: CSSProperties
  className?: string
  wheel?: boolean
  autoScroll?: boolean
}

const isInViewFromRight = (element: Element, parent: Element) => {
  const { x: elementX, width: elementWidth } = element.getBoundingClientRect()
  const { x: parentX, width: parentWidth } = parent.getBoundingClientRect()

  return elementX + elementWidth <= parentX + parentWidth
}

const isInViewFromBottom = (element: Element, parent: Element) => {
  const { y: elementY, height: elementHeight } = element.getBoundingClientRect()
  const { y: parentY, height: parentHeight } = parent.getBoundingClientRect()

  return elementY + elementHeight <= parentY + parentHeight
}

const isInViewFromLeft = (element: Element, parent: Element) => {
  const { x: elementX } = element.getBoundingClientRect()
  const { x: parentX } = parent.getBoundingClientRect()

  return elementX >= parentX
}

const isInViewFromTop = (element: Element, parent: Element) => {
  const { y: elementY } = element.getBoundingClientRect()
  const { y: parentY } = parent.getBoundingClientRect()

  return elementY >= parentY
}

const firstNotInViewFromEnd = (children: HTMLCollection, parent: Element, vertical = false) => {
  const isInView = vertical ? isInViewFromBottom : isInViewFromRight
  for (let index = 0; index < children.length; index++) {
    const child = children[index]
    if (!isInView(child, parent)) {
      return child
    }
  }

  return null
}

const firstNotInViewFromStart = (children: HTMLCollection, parent: Element, vertical = false) => {
  const isInView = vertical ? isInViewFromTop : isInViewFromLeft
  for (let index = children.length - 1; index >= 0; index--) {
    const child = children[index]
    if (!isInView(child, parent)) {
      return child
    }
  }

  return null
}

const getButtonStyle = (hidden: boolean, vertical: boolean, size: 'small' | 'default'): CSSProperties => {
  return {
    margin: size === 'default' ? '6px' : '0',
    visibility: hidden ? 'hidden' : 'visible',
    transform: vertical ? 'rotateZ(90deg)' : '',
    transition: 'none',
  }
}

const Carousel = ({ children, vertical = false, size = 'default', style, className, wheel, autoScroll }: Props) => {
  const [firstInView, setFirstInView] = useState(false)
  const [lastInView, setLastInView] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref: any = useRef()
  const intervalRef = useRef<any>(null)

  /**
   * Check if first and last items are in viewport and set relevant states
   */
  const setEdgeItemsState = () => {
    if (ref.current.children.length === 0) {
      setFirstInView(true)
      setLastInView(true)

      return
    }
    const isFirstInViewFunc = vertical ? isInViewFromTop : isInViewFromLeft
    const isLastInViewFunc = vertical ? isInViewFromBottom : isInViewFromRight
    const isFirstInView = isFirstInViewFunc(ref.current.children[0], ref.current.parentElement)
    const isLastInView = isLastInViewFunc(ref.current.children[ref.current.children.length - 1], ref.current.parentElement)
    setFirstInView(isFirstInView)
    setLastInView(isLastInView)
  }

  useEffect(() => {
    const onResize = _throttle(setEdgeItemsState, 300)
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // For some reason in several cases width of items is 0 at this moment
    setTimeout(() => {
      setEdgeItemsState()
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, vertical])

  const goNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    const element = firstNotInViewFromEnd(ref.current.children, ref.current.parentElement, vertical)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: vertical ? 'nearest' : 'start',
        block: vertical ? 'start' : 'nearest',
      })
      setEdgeItemsState()
    }
  }

  const goPrev: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    const element = firstNotInViewFromStart(ref.current.children, ref.current.parentElement, vertical)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: vertical ? 'nearest' : 'end',
        block: vertical ? 'end' : 'nearest',
      })
      setEdgeItemsState()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useMemo(() => _debounce(setEdgeItemsState, 150), [])

  const handleMouseWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (!wheel) {
      return
    }
    const x = vertical ? 0 : e.deltaY
    const y = vertical ? e.deltaY : 0
    ;(ref.current.parentElement as HTMLDivElement).scrollBy(x, y)
  }

  useEffect(() => {
    if (!autoScroll || !!intervalRef.current) {
      return
    }

    intervalRef.current = setInterval(() => {
      (ref.current.parentElement as HTMLDivElement).scrollBy(1, 0)
    }, 50)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }  
    }
  }, [autoScroll])

  const handleTouchStart = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  return (
    <Root vertical={vertical}>
      {!isMobile() && <Button shape="circle" icon={<LeftOutlined />} style={getButtonStyle(firstInView, vertical, size)} onClick={goPrev} />}
      <Outer onScroll={handleScroll} onTouchStart={handleTouchStart} onWheel={handleMouseWheel} vertical={vertical} overflow={isMobile()? 'overlay' : 'hidden'}>
        <Inner vertical={vertical} ref={ref} style={style} className={className}>
          {children}
        </Inner>
      </Outer>
      {!isMobile() && <Button shape="circle" icon={<RightOutlined />} style={getButtonStyle(lastInView, vertical, size)} onClick={goNext} />}
    </Root>
  )
}

const Root = styled.div<{ vertical: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
      height: 100%;
    `}
`

const Outer = styled.div<{ vertical: boolean, overflow: 'hidden' | 'overlay' }>`
  overflow: ${({overflow}) => overflow};
  ${(props) =>
    props.vertical &&
    css`
      width: 100%;
    `}
`

const Inner = styled.div<{ vertical: boolean }>`
  display: flex;
  width: max-content;
  margin-bottom: 10px;
  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
      width: auto;
    `}
`

export default Carousel
