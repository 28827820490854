import { Button, Form, Input, message, Result } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import emailjs from '@emailjs/browser'
import { useNavigate } from 'react-router-dom'

const ContactUs = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState<'form' | 'sending' | 'sent'>('form')
  const handleFinish = async (values: any) => {
    setStatus('sending')
    try {
      await emailjs.send('service_6at2fyr', 'template_prlg3dn', values, 'uHt2-TOX6Cr3WoZIq')
      setStatus('sent')
    } catch (error) {
      message.error('Something went wrong... Please try again later.')
      setStatus('form')
    }
  }

  const handleBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <Root>
      {status === 'sent' && <Result status="success" title="Amazing! We will read your message and get back to you soon!" extra={<Button onClick={handleBack}>Back to shop</Button>} />}
      {status !== 'sent' && <Form name="contact" layout="vertical" onFinish={handleFinish}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter valid email' },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter message' }]}>
          <Input.TextArea cols={30} rows={5} size="large" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 12 }}>
          <Button type="primary" htmlType="submit" size="large" loading={status === 'sending'}>
            Send
          </Button>
        </Form.Item>
      </Form>}
    </Root>
  )
}

const Root = styled.div`
  max-width: 1000px;
  padding: 40px;
  background-color: #fafafa;
  margin: 0 auto;
`

export default ContactUs
