import { Card } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Retailer, Seller } from '../../models/OtherStore'

const { Meta } = Card

interface Props {
  title: string
  retailer: Retailer
  seller: Seller
  hasShipping: boolean
}

const SellerCard = ({ retailer, seller, hasShipping, title }: Props) => {
  return (
    <Card title={retailer} bordered={false} size="small">
      <StyledMeta title="Title" description={title} />
      {retailer === 'AliExpress' && (
        <>
          <StyledMeta title="Seller" description={seller.username} />
          <StyledMeta title="Seller's Store" description={seller.shopUrl} />
        </>
      )}
      {retailer === 'eBay' && (
        <>
          <StyledMeta title="Seller" description={seller.username} />
          <StyledMeta title="Feedback Score" description={seller.feedbackScore} />
          <StyledMeta title="Positive feedback" description={seller.feedbackPercentage + '%'} />
        </>
      )}
      {retailer === 'amazon' && (
        <>
          <StyledMeta title="Feedback Score" description={seller.feedbackScore} />
          <StyledMeta title="Positive feedback" description={seller.feedbackPercentage + ' out of 5'} />
        </>
      )}
      {hasShipping && <Shipping>* Shipping cost is included!</Shipping>}
    </Card>
  )
}

const StyledMeta = styled(Meta)`
  > .ant-card-meta-detail {
    display: flex;
    align-items: baseline;
    gap: 10px;

    > .ant-card-meta-title {
      &::after {
        content: ':';
      }
    }

    > .ant-card-meta-description {
      max-width: 300px;
    }
  }
`

const Shipping = styled.div`
  font-weight: bold;
  margin-top: 10px;
`

export default SellerCard
