import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { isMobile } from '../../common/utils'
import { IComparedItem } from '../../models/ComparedItem'
import ProductsList from '../ProductsList/ProductsList'

interface Props {
  items: IComparedItem[]
  isLoading: boolean
  currency: string
}

const RecommendedItems = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Root $extend={isMobile()}>
      <Title>{t('recommended')}</Title>
      <ProductsList {...props} />
    </Root>
  )
}

const Root = styled.div<{ $extend: boolean }>`
  background-color: rgb(104,177,252, 0.1);
  padding: 10px 0;
  ${(props) =>
    props.$extend &&
    css`
      margin: 0 -20px 20px -20px;
    `}
`

const Title = styled.div`
  text-align: center;
  font-size: 18px;
  padding-bottom: 5px;
`



export default RecommendedItems