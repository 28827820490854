import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import productsApi from "../api/products"

export default function useSettings() {
  const [selectedCountry, setSelectedCountry] = useState<string>(localStorage.getItem('country') || 'US')
  const [selectedCurrency, setSelectedCurrency] = useState<string>(localStorage.getItem('currency') || 'USD')
  const { i18n } = useTranslation()

  const saveSelectedCountry = (value: string) => {
    setSelectedCountry(value)
    localStorage.setItem('country', value)
  }

  const saveSelectedCurrency = (value: string) => {
    setSelectedCurrency(value)
    localStorage.setItem('currency', value)
  }

  useEffect(() => {
    if (!!localStorage.getItem('currency') && !!localStorage.getItem('country')) {
      return
    }
    productsApi.cookieAccepted().then(({ currency, country_code }) => {
      saveSelectedCountry(country_code)
      saveSelectedCurrency(currency)
    })
  }, [])

  useEffect(() => {
    i18n.changeLanguage(selectedCountry === "IL" ? "he" : "en")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  return {
    selectedCountry, selectedCurrency, saveSelectedCountry, saveSelectedCurrency
  }

}