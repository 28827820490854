import axios from 'axios'
import { IOtherStore } from '../models/OtherStore'

axios.defaults.baseURL = '/api/v1/'

const browse = (country = 'IL', currency = 'NIS', offset = 0, searchString?: string) => {
  return axios.post('browse', { country, currency, offset, searchString }).then(response => response.data)
}

const browseAmazon = (country = 'IL', currency = 'NIS', offset = 0, searchString?: string) => {
  return axios.post('browseAmazon', { country, currency, offset, searchString }).then(response => response.data)
}

const getReviews = (country = 'IL', currency = 'NIS') => {
  return axios.post('reviews', { country, currency }).then(response => response.data)
}

const getAutoComplete = (searchString: string) => {
  return axios.post('getAutoComplete', { searchString }).then(response => response.data)
}

const cookieAccepted = () => {
  return axios.get('cookieAccepted').then(response => response.data)
}

const goToProduct = ({ itemUrl, title, price }: IOtherStore) => {
  return axios.post('goToProduct', {
    url: itemUrl,
    title: title,
    price: price.calcTotalPrice + price.calcCurrency
  })
}

const productsApi = {
  browse, getAutoComplete, cookieAccepted, browseAmazon, goToProduct, getReviews
}

export default productsApi