import axios from 'axios'
import config from './assets-config.json'

const env: string = process.env.REACT_APP_ENV || 'testing'

const { url, token, api } = (config as any)[env]

const banners = (locale = 'en') => {
  return axios.get(api + 'banners', {
    params: {
      populate: '*',
      sort: 'order',
      locale,
      'filters[active][$eq]': 'true'
    },
    headers: {
      Authorization: `bearer ${token}`
    }
  }).then(response => response.data.data)
}

const assetsApi = {
  url,
  banners
}

export default assetsApi