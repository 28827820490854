import { Dropdown, Menu } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import React from 'react'
import { BsSortNumericDown, BsSortNumericDownAlt} from 'react-icons/bs'
import { VscSortPrecedence } from 'react-icons/vsc'
import { SORT_TYPES } from '../../common/Constants'
import { MenuButton } from '../MenuButton/MenuButton'

interface Props {
  value: string
  onChange: (value: string) => void
}

const ICONS = {
  'compopo-sort': VscSortPrecedence,
  'lowest-first': BsSortNumericDown,
  'highest-first': BsSortNumericDownAlt

}

const OrderMenu = ({ value, onChange }: Props) => {
  return (
    <Dropdown trigger={['click']} overlay={renderMenu(value, ({ key }) => onChange(key))}>
      <MenuButton>
        {getIcon(value, {fontSize: '18px'})}
      </MenuButton>
    </Dropdown>
  )
}

const renderMenu = (key: string, onClick: MenuClickEventHandler) => {
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[key]}
      items={[
        { label: 'Order by', type: 'group' },
        ...SORT_TYPES.map(({ value, label }) => ({
          key: value,
          label: (
            <div>
              {getIcon(value, { marginRight: '10px', fontSize: '16px' })}
              <span>{label}</span>
            </div>
          ),
        })),
      ]}
    />
  )
}

const getIcon = (value: string, style?: React.CSSProperties) => {
  const Icon = (ICONS as any)[value]

  return <Icon style={style} />
}

export default OrderMenu
