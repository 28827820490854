import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CountriesMenu from './CountriesMenu'
import CurrenciesMenu from './CurrenciesMenu'
import Hamburger from './Hamburger'
import OrderMenu from './OrderMenu'

interface Props {
  country: string
  currency: string
  orderBy: string
  onChangeCountry: (value: string) => void
  onChangeCurrency: (value: string) => void
  onChangeOrder: (value: string) => void
  isMobileMain: boolean
  children?: React.ReactNode
}

const Header = ({ children, country, currency, orderBy, isMobileMain, onChangeCountry, onChangeCurrency, onChangeOrder }: Props) => {
  return (
    <Root $transparent={isMobileMain}>
      <Link to="/">
        <Logo src="/compologo.png" alt="Compopo" />
      </Link>
      <Hamburger />
      <Children>{children}</Children>
      <Actions>
        <CountriesMenu value={country} onChange={onChangeCountry} />
        <CurrenciesMenu value={currency} onChange={onChangeCurrency} />
        <OrderMenu value={orderBy} onChange={onChangeOrder} />
      </Actions>
    </Root>
  )
}

const Root = styled.div<{$transparent: boolean}>`
  transition: background-color 0.5s;
  background-color: ${props => props.$transparent ? 'transparent' : '#024f7d'};
  z-index: 2;
  position: ${props => props.$transparent ? 'absolute': 'relative'};
  display: flex;
  align-items: center;
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const Logo = styled.img`
  height: 75px;
`

const Children = styled.div`
  flex: 1;
  margin: 0 20px;
  @media screen and (max-width: 650px) {
    min-width: 80%;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`

export default Header
