import { Skeleton } from 'antd'
import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  short?: boolean
  title?: string
}

const SkeletonCard = ({ short = false, title = 'Compopo' }: Props) => {

  const width = short ? '200px' : '250px'
  const height = short ? '60px' : '250px'

  return (
    <Root $short={short}>
      <ImageBox $short={short}>
        <Skeleton.Node active style={{ width, height }}>
          <Placeholder $short={short}>{title}</Placeholder>
        </Skeleton.Node>
      </ImageBox>
      <Skeleton active title={false} paragraph={{ rows: short ? 1 : 3 }} />
      <List $short={short}>
        <Skeleton.Input active block style={{height: short ? '20px' : '32px'}} />
        {!short && (
          <>
            <Skeleton.Input active block />
            <Skeleton.Input active block />
            <Skeleton.Input active block />
          </>
        )}
      </List>
    </Root>
  )
}

export default SkeletonCard

const Root = styled.div<{ $short: boolean }>`
  background: #fff;
  transition: box-shadow 0.3s;
  box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  width: 100%;
  padding: ${props => props.$short ? '10px' : '20px'};
  box-sizing: border-box;
  max-width: 290px;
  height: fit-content;
  @media screen and (max-width: 650px) {
    max-width: inherit;
    ${(props) =>
      props.$short &&
      css`
        width: 90%;
      `}
  }
`

const Placeholder = styled.div<{ $short: boolean }>`
  color: #bfbfbf;
  font-weight: bold;
  font-size: ${props => props.$short ? '26px' : '36px'};
`

const List = styled.div<{$short: boolean}>`
  ${props => props.$short && css`margin-top: -8px;`}
  > div {
    margin-top: ${props => props.$short ? '0' : '10px'};
  }
`

const ImageBox = styled.div<{$short: boolean}>`
  text-align: center;
  margin-bottom: ${props => props.$short ? '7px': '20px'};
`
