import { Carousel } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { isMobile } from '../../common/utils'
import { useBannersQuery } from './useBannersQuery'

const field = isMobile() ? 'mobile_src' : 'pc_src'

const Banners = () => {
  const { data } = useBannersQuery()
  
  return (
    <StyledCarousel autoplay autoplaySpeed={5000} dots={!isMobile()}>
      {data?.map(({ id, alt, href, ...rest }) => (
        <React.Fragment key={id}>
          {!href ? (
            <Image src={rest[field]} alt={alt} />
          ) : (
            <a href={href} rel="noreferrer">
              <Image src={rest[field]} alt={alt} />
            </a>
          )}
        </React.Fragment>
      ))}
    </StyledCarousel>
  )
}

const StyledCarousel = styled(Carousel)`
  ul.slick-dots {
    bottom: 0;
    > li {
      padding: 10px 0;
      &:hover {
        > button {
          transform: scaleY(5);
          border-radius: 50%;
        }
      }
    }
  }
`

const Image = styled.img`
  width: 100%;
  display: inline-block;
`

export default Banners
