import React from 'react'
import { Dropdown, Menu } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { CURRENCIES } from '../../common/Constants'
import { MenuButton } from '../MenuButton/MenuButton'

interface Props {
  value: string
  onChange: (value: string) => void
}

const CurrenciesMenu = ({ value, onChange }: Props) => {
  const currency = CURRENCIES.find((c) => c.value === value)
  return (
    <Dropdown trigger={['click']} overlay={renderMenu(value, ({ key }) => onChange(key))}>
      <MenuButton>{`${currency?.label} ${currency?.value}`}</MenuButton>
    </Dropdown>
  )
}

const renderMenu = (key: string, onClick: MenuClickEventHandler) => {
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[key]}
      items={[
        { label: 'Currency', type: 'group' },
        ...CURRENCIES.map(({ value, label }) => ({
          key: value,
          label: `${label} ${value}`,
        })),
      ]}
    />
  )
}

export default CurrenciesMenu
