import React, { useState } from 'react'
import useAutoCompleteQuery from '../../hooks/useAutoCompleteQuery'
import SearchInput from './SearchInput'

interface Props {
  defaultValue?: string
  onSearch: (term: string) => void
}

const SearchInputConnected = (props: Props) => {
  const [term, setTerm] = useState('')
  const { data: autoCompleteList } = useAutoCompleteQuery(term)
  return <SearchInput onInput={setTerm} list={autoCompleteList || []} placeholder="What would you like to buy?" {...props} />
}

export default SearchInputConnected
