import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Title } = Typography

const QnA = () => {
  return (
    <Root>
      <Title level={2}>Questions and Answers</Title>
      <Question>QWhat is Compopo?</Question>
      <p>
        We have built a smart and innovative tool for comparing prices across different retailers (such as eBay sites, AliExpress, etc...) all over the globe, so that you may find the best price for your desired product.
      </p>
      <Question>QWhy do I need Compopo?</Question>
      <p>
        We tend to buy products from the same favourite eBay site (US, UK, etc…). The thing is, that the product we are looking for can be much cheaper on a different eBay site. This is where we take action, and help you
        find the right place to make your purchase.
      </p>
      <Question>QWas the purchase made using Compopo or on the retailers (e.g eBay, AliExpress, etc..)?</Question>
      <p>
        All the purchases and transactions are made <b>by the retailers only</b>. Compopo only helps you to find the site that sells your requested product at the lowest price
      </p>
      <Question>QDoes Compopo save any of my personal details?</Question>
      <p>Absolutely not, all users' personal data and the information regarding the user’s eBay account is not accessible by Compopo. Compopo only helps you to find the lowest price in the world.</p>
      <Question>QDo I pay Compopo when buying from eBay / AliExpress / others?</Question>
      <p>No, we don’t take a single penny out of our users. You may check us on that - The price we show you is the same price you will see when searching the product by yourself.</p>
    </Root>
  )
}

const Root = styled.div`
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
`

const Question = styled.p`
  &::first-letter {
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bold;
    margin-right: 5px;
  }
`

export default QnA
