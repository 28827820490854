export const COUNTRIES = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Austria',
    value: 'AT',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Italy',
    value: 'IT',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Poland',
    value: 'PL',
  },
  {
    label: 'Romania',
    value: 'RO',
  },
  {
    label: 'Russia',
    value: 'RU',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  }
]

export const CURRENCIES = [
  { value: 'NIS', label: '₪' },
  { value: 'USD', label: '$' },
  { value: 'EUR', label: '€' },
  { value: 'GBP', label: '£' },
]

export const SORT_TYPES = [
  { value: 'compopo-sort', label: 'Compopo Sort'},
  { value: 'lowest-first', label: 'Price - Lowest First'},
  { value: 'highest-first', label: 'Price -Highest First'}
]
