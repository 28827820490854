import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import productsApi from "../api/products";
import mixpanel from "mixpanel-browser";

export default function useProductsQuery(country: string, currency: string, searchString?: string) {
  return useInfiniteQuery(
    ['browse', country, searchString],
    ({ pageParam = 0 }) => productsApi.browse(country, currency, pageParam, searchString),
    {
      cacheTime: 0,
      retry: 1,
      getNextPageParam: (lastPage: any, allPages: any[]) => {
        if (!lastPage.isLoadMore) {
          return undefined
        }

        return allPages.length
      },
      onSuccess: () => {
        if (!searchString) {
          mixpanel.track('Someone is here')
        }
      }
    }
  )
}

export function useAmazonProductsQuery(country: string, currency: string, offset: number, searchString?: string) {
  return useQuery(['browseAmazon', country, offset, searchString], () => productsApi.browseAmazon(country, currency, offset, searchString), { cacheTime: 0, retry: 1, enabled: !!searchString })
}

export function useProductReviewsQuery(country: string, currency: string, searchString?: string) {
  return useQuery(['reviews', country], () => productsApi.getReviews(country, currency), { cacheTime: 0, retry: 1, enabled: !searchString })
}