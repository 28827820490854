import React from 'react'
import { FacebookFilled, InstagramFilled, MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import styled from 'styled-components'
import { FaTiktok } from 'react-icons/fa'
import { MenuButton } from '../MenuButton/MenuButton'
import { Link } from 'react-router-dom'

const Hamburger = () => {
  return (
    <Dropdown trigger={['click']} overlay={renderMenu()}>
      <Wrapper>
        <MenuButton icon={<MenuOutlined />} />
      </Wrapper>
    </Dropdown>
  )
}

const renderMenu = () => {
  return (
    <Menu
      items={[
        { label: <a href="/termsofuse" rel="noreferrer" target="_blank">Terms of use</a>, key: 1 },
        { label: <a href="/qna" rel="noreferrer" target="_blank">Q{'&'}A</a>, key: 2 },
        { label: <a href="https://youtu.be/8mn_I6cub8Y" target="_blank" rel="noreferrer">About Us</a>, key: 3 },
        { label: <a href="https://www.freeprivacypolicy.com/live/ee56ac73-98b3-4233-b5b1-d6cbcd35f80f" rel="noreferrer" target="_blank">Privacy policy</a>, key: 4 },
        { label: <a href="https://www.termsfeed.com/live/b42b2cf2-4847-4b76-8130-f24edb695618" rel="noreferrer" target="_blank">Cookies policy</a>, key: 5 },
        { label: <Link to="/contact">Contact us</Link>, key: 6 },
        {
          label: (
            <Follow>
              Follow:
              <MenuRow>
                <Button icon={<FacebookFilled />} type="link" href="https://www.facebook.com/Compopo-102102265712707/" target="_blank" />
                <Button icon={<InstagramFilled />} type="link" href="https://instagram.com/compopo2021?utm_medium=copy_link" target="_blank" />
                <Button icon={<FaTiktok />} type="link" href="https://vm.tiktok.com/ZSeCHbATm/" target="_blank" />
              </MenuRow>
            </Follow>
          ),
          style: {pointerEvents: 'none'},
          key: 7,
        },
      ]}
    />
  )
}

const Follow = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

const Wrapper = styled.div`
  @media screen and (max-width: 650px) {
    position: absolute;
    left: 5px;
    top: 5px;
  }
`

const MenuRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  pointer-events: initial;
  > a {
    color: #379cf2;
    &:hover {
      color: #fff;
      background-color: #379cf2;
    }
  }
`

export default Hamburger
