import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import assetsApi from "../../api/assets";

export function useBannersQuery() {
  const { i18n } = useTranslation()
  const lang = getLanguage(i18n.language)
  return useQuery(['banners', lang], () => assetsApi.banners(lang), {
    placeholderData: [], select: (data: any[]) => data.map(({ id, attributes }) => ({
      id, alt: attributes.alt, href: attributes.href, pc_src: assetsApi.url + attributes.pc.data.attributes.url, mobile_src: assetsApi.url + attributes.mobile.data.attributes.url
    }))
  })
}

const getLanguage = (locale: string) => {
  return ['en', 'he'].includes(locale) ? locale : 'en'
}