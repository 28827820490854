import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import _debounce from 'lodash/debounce'
import { AutoComplete, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { isMobile } from '../../common/utils'

interface Props {
  onInput: (term: string) => void
  onSearch: (term: string) => void
  defaultValue?: string
  list?: string[]
  delay?: number
  placeholder?: string
}

const SearchInput = ({ onInput, onSearch, placeholder, defaultValue, delay = 200, list = [] }: Props) => {
  const [value, setValue] = useState(defaultValue || '')
  const inputRef = useRef<any>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputDebounced = useMemo(() => _debounce((term: string) => onInput(term), delay), [])
  const handleSelect = (v: any) => {
    setValue(v)
    onSearch(v)
    inputRef.current.blur()
  }
  const handleSearch = (v: string) => {
    setValue(v)
    inputDebounced(v)
  }
  const handleSubmit = (e: React.FormEvent) => {
    onSearch(value)
    inputRef.current.blur()
    e.preventDefault()
  }
  useEffect(() => {
    setValue(defaultValue || '')
  }, [defaultValue])
  return (
    <Root onSubmit={handleSubmit}>
      <StyledAutoComplete
        placeholder={placeholder}
        ref={inputRef}
        style={{ width: '100%' }}
        size="large"
        allowClear
        autoFocus={!isMobile()}
        value={value}
        backfill={true}
        options={list.map((value) => ({ value }))}
        onSearch={handleSearch}
        onSelect={handleSelect}
      />
      <StyledButton size="large" htmlType="submit" type="primary" icon={<SearchOutlined />}></StyledButton>
    </Root>
  )
}

const Root = styled.form`
  display: flex;
`

const StyledAutoComplete = styled(AutoComplete)`
  .anticon {
    line-height: 1;
  }
`

const StyledButton = styled(Button)`
  background: #379cf2;
  border-color: #379cf2;
`

export default SearchInput
